import { HttpError } from './errors'
import * as Sentry from '@sentry/browser'

export type ApiData = any

export type ApiResponse = {
  [key: string]: any
}

export type ApiRequestBody = {
  [key: string]: any
}

export type ApiRequest = {
  path: string
  method: string
  body?
  query?: ApiRequestBody
}

export enum RequestState {
  NotLoaded = 'NOT_LOADED',
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Error = 'ERROR'
}

function createHeaders() {
  const headers: { [key: string]: string } = {
    Accept: 'application/vnd.papertrail.api+json; version=1.0',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  }

  const currentLanguage = 'en' //getCurrentLanguage()
  if (currentLanguage === 'en') {
    headers['Accept-Language'] = currentLanguage
  } else {
    headers['Accept-Language'] = currentLanguage + ',en'
  }

  return headers
}

function withTimeout(ms: number, promise: Promise<ApiResponse>): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Promise timeout.'))
    }, ms)
    promise.then(
      (res: ApiResponse) => {
        clearTimeout(timeoutId)
        resolve(res)
      },
      (err: Error) => {
        clearTimeout(timeoutId)
        reject(err)
      }
    )
  })
}

/**
 * Use when response type is not json
 * @param apiRequest
 */
export async function requestRaw(apiRequest: ApiRequest) {
  return request(apiRequest, async (response) => {
    return await response.text()
  })
}

/**
 * Use when response type is json
 * @param apiRequest
 */
export async function requestJson(apiRequest: ApiRequest) {
  return request(apiRequest, async (response) => {
    try {
      return await response.json()
    } catch (e) {
      throw new HttpError(response.status as number, 'not_json', 'Expected json response', {}, apiRequest)
    }
  })
}

async function request(apiRequest: ApiRequest, responseHandler) {
  const headers = await createHeaders()

  if (apiRequest.body instanceof FormData) {
    delete headers['Content-Type']
  }

  const args: RequestInit = {
    method: apiRequest.method,
    body: apiRequest.body instanceof FormData ? apiRequest.body : JSON.stringify(apiRequest.body),
    headers: headers
  }

  const timeoutMs = 25000
  let response: ApiResponse

  let url = '/api' + apiRequest.path
  if (apiRequest.query) {
    url = `${url}?${new URLSearchParams(apiRequest.query).toString()}`
  }

  try {
    response = await withTimeout(timeoutMs, fetch(new Request(url, args)))
  } catch {
    throw new HttpError(408, `time_out`, 'The request timed out', {}, apiRequest)
  }

  if (response && response.ok) {
    return responseHandler(response)
  } else {
    await handleError(apiRequest, response)
  }
}

/**
 * The errors to mute are copied over from the webclient
 */
const sentryMutedErrors = [
  'invalid_credentials',
  'login_attempts_exceeded',
  'invalid_permission',
  'account_deactivated',
  'account_not_found',
  'record_not_found',
  'invalid_partner_permission',
  'folder_not_found',
  'product_not_found',
  'state_not_found',
  'user_does_not_belong_to_account',
  'user_does_not_have_permission_for_folder',
  'user_is_not_an_owner',
  'user_does_not_belong_to_subscription',
  'time_out',
  'Unauthenticated'
]

/**
 * The API returns errors in the format below.
 * {
 *    "code":400,
 *     "error":"validation_error",
 *     "message":"The request had a validation error.",
 *     "data":{ ...}
 * }
 * This method parses the json and logs to Sentry if the error is not in the muted list.
 * It throws an HttpError which contains any info in `data` so that this can be used by the calling method.
 * @param req
 * @param resp
 */
async function handleError(req: ApiRequest, resp: ApiResponse) {
  let details
  let body
  try {
    body = await resp.text()
    details = JSON.parse(body)
  } catch {
    const message = body
    details = { error: 'unknown', message }
  }

  const { error, message, ...rest } = details

  if (sentryMutedErrors.indexOf(error) == -1) {
    Sentry.captureMessage('HttpError ' + resp.status + ': ' + error, {
      level: Sentry.Severity.Error,
      extra: { ...rest, request: { path: req.path, query: req.query }, message }
    })
  }
  if (resp.status === 401) {
    window.dispatchEvent(new Event('papertrail:unauthenticated'))
  }
  throw new HttpError(resp.status as number, error, message, rest, req)
}
